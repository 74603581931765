import { Box, Button, Container, Heading, Image, Text } from '@chakra-ui/react';
import PageHead from 'application-flow/components/PageHead';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

const ErrorPage404 = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHead pageTitle="404 Page Not Found" />
      <Container
        maxW="1200px"
        py={{ base: 20, sm: 40 }}
        height="100vh"
        display="flex"
        flexDir="column"
        justifyContent="center"
      >
        <Box pos="absolute" right="0" bottom="0" zIndex="-1">
          {/* <svg width="989" height="1200" viewBox="0 0 989 800" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1293.93 1250.57L670.612 35.9996L70.1515 1235.9" stroke="#ED4746" strokeWidth="156.339" strokeLinejoin="bevel" />
        </svg> */}
          <Image src="/assets/assets-a.png" height="90vh" alt="antler-A" maxW="unset" />
        </Box>
        <Heading size="4xl" fontWeight="bold" color={{ base: 'neutral.900', sm: 'primary.500' }} mb="8">
          404
        </Heading>
        <Text fontSize="xl" mb="12" maxWidth="600px">
          {t('404_title', 'Oh no! This page either does not exist or has been moved to a new location.')}
        </Text>

        <Box as={Link} href="/">
          <Button display="block" mb="4" width="max-content">
            {t('404_goToDashboard', 'Go To Dashboard')}
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default ErrorPage404;
